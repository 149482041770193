import _outlayer from "outlayer";
import _rect from "./rect";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * Packery Item Element
**/
(function (window, factory) {
  // universal module definition

  /* jshint strict: false */

  /* globals define, module, require */
  if (exports) {
    // CommonJS
    exports = factory(_outlayer, _rect);
  } else {
    // browser global
    window.Packery.Item = factory(window.Outlayer, window.Packery.Rect);
  }
})(window, function factory(Outlayer, Rect) {
  'use strict'; // -------------------------- Item -------------------------- //

  var docElemStyle = document.documentElement.style;
  var transformProperty = typeof docElemStyle.transform == "string" ? "transform" : "WebkitTransform"; // sub-class Item

  var Item = function PackeryItem() {
    Outlayer.Item.apply(this || _global, arguments);
  };

  var proto = Item.prototype = Object.create(Outlayer.Item.prototype);
  var __create = proto._create;

  proto._create = function () {
    // call default _create logic
    __create.call(this || _global);

    (this || _global).rect = new Rect();
  };

  var _moveTo = proto.moveTo;

  proto.moveTo = function (x, y) {
    // don't shift 1px while dragging
    var dx = Math.abs((this || _global).position.x - x);
    var dy = Math.abs((this || _global).position.y - y);
    var canHackGoTo = (this || _global).layout.dragItemCount && !(this || _global).isPlacing && !(this || _global).isTransitioning && dx < 1 && dy < 1;

    if (canHackGoTo) {
      this.goTo(x, y);
      return;
    }

    _moveTo.apply(this || _global, arguments);
  }; // -------------------------- placing -------------------------- //


  proto.enablePlacing = function () {
    this.removeTransitionStyles(); // remove transform property from transition

    if ((this || _global).isTransitioning && transformProperty) {
      (this || _global).element.style[transformProperty] = "none";
    }

    (this || _global).isTransitioning = false;
    this.getSize();

    (this || _global).layout._setRectSize((this || _global).element, (this || _global).rect);

    (this || _global).isPlacing = true;
  };

  proto.disablePlacing = function () {
    (this || _global).isPlacing = false;
  }; // -----  ----- //
  // remove element from DOM


  proto.removeElem = function () {
    var parent = (this || _global).element.parentNode;

    if (parent) {
      parent.removeChild((this || _global).element);
    } // add space back to packer


    (this || _global).layout.packer.addSpace((this || _global).rect);

    this.emitEvent("remove", [this || _global]);
  }; // ----- dropPlaceholder ----- //


  proto.showDropPlaceholder = function () {
    var dropPlaceholder = (this || _global).dropPlaceholder;

    if (!dropPlaceholder) {
      // create dropPlaceholder
      dropPlaceholder = (this || _global).dropPlaceholder = document.createElement("div");
      dropPlaceholder.className = "packery-drop-placeholder";
      dropPlaceholder.style.position = "absolute";
    }

    dropPlaceholder.style.width = (this || _global).size.width + "px";
    dropPlaceholder.style.height = (this || _global).size.height + "px";
    this.positionDropPlaceholder();

    (this || _global).layout.element.appendChild(dropPlaceholder);
  };

  proto.positionDropPlaceholder = function () {
    (this || _global).dropPlaceholder.style[transformProperty] = "translate(" + (this || _global).rect.x + "px, " + (this || _global).rect.y + "px)";
  };

  proto.hideDropPlaceholder = function () {
    // only remove once, #333
    var parent = (this || _global).dropPlaceholder.parentNode;

    if (parent) {
      parent.removeChild((this || _global).dropPlaceholder);
    }
  }; // -----  ----- //


  return Item;
});

export default exports;