var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * Rect
 * low-level utility class for basic geometry
 */
(function (window, factory) {
  // universal module definition

  /* jshint strict: false */

  /* globals define, module */
  if (exports) {
    // CommonJS
    exports = factory();
  } else {
    // browser global
    window.Packery = window.Packery || {};
    window.Packery.Rect = factory();
  }
})(window, function factory() {
  'use strict'; // -------------------------- Rect -------------------------- //

  function Rect(props) {
    // extend properties from defaults
    for (var prop in Rect.defaults) {
      (this || _global)[prop] = Rect.defaults[prop];
    }

    for (prop in props) {
      (this || _global)[prop] = props[prop];
    }
  }

  Rect.defaults = {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  };
  var proto = Rect.prototype;
  /**
   * Determines whether or not this rectangle wholly encloses another rectangle or point.
   * @param {Rect} rect
   * @returns {Boolean}
  **/

  proto.contains = function (rect) {
    // points don't have width or height
    var otherWidth = rect.width || 0;
    var otherHeight = rect.height || 0;
    return (this || _global).x <= rect.x && (this || _global).y <= rect.y && (this || _global).x + (this || _global).width >= rect.x + otherWidth && (this || _global).y + (this || _global).height >= rect.y + otherHeight;
  };
  /**
   * Determines whether or not the rectangle intersects with another.
   * @param {Rect} rect
   * @returns {Boolean}
  **/


  proto.overlaps = function (rect) {
    var thisRight = (this || _global).x + (this || _global).width;
    var thisBottom = (this || _global).y + (this || _global).height;
    var rectRight = rect.x + rect.width;
    var rectBottom = rect.y + rect.height; // http://stackoverflow.com/a/306332

    return (this || _global).x < rectRight && thisRight > rect.x && (this || _global).y < rectBottom && thisBottom > rect.y;
  };
  /**
   * @param {Rect} rect - the overlapping rect
   * @returns {Array} freeRects - rects representing the area around the rect
  **/


  proto.getMaximalFreeRects = function (rect) {
    // if no intersection, return false
    if (!this.overlaps(rect)) {
      return false;
    }

    var freeRects = [];
    var freeRect;
    var thisRight = (this || _global).x + (this || _global).width;
    var thisBottom = (this || _global).y + (this || _global).height;
    var rectRight = rect.x + rect.width;
    var rectBottom = rect.y + rect.height; // top

    if ((this || _global).y < rect.y) {
      freeRect = new Rect({
        x: (this || _global).x,
        y: (this || _global).y,
        width: (this || _global).width,
        height: rect.y - (this || _global).y
      });
      freeRects.push(freeRect);
    } // right


    if (thisRight > rectRight) {
      freeRect = new Rect({
        x: rectRight,
        y: (this || _global).y,
        width: thisRight - rectRight,
        height: (this || _global).height
      });
      freeRects.push(freeRect);
    } // bottom


    if (thisBottom > rectBottom) {
      freeRect = new Rect({
        x: (this || _global).x,
        y: rectBottom,
        width: (this || _global).width,
        height: thisBottom - rectBottom
      });
      freeRects.push(freeRect);
    } // left


    if ((this || _global).x < rect.x) {
      freeRect = new Rect({
        x: (this || _global).x,
        y: (this || _global).y,
        width: rect.x - (this || _global).x,
        height: (this || _global).height
      });
      freeRects.push(freeRect);
    }

    return freeRects;
  };

  proto.canFit = function (rect) {
    return (this || _global).width >= rect.width && (this || _global).height >= rect.height;
  };

  return Rect;
});

export default exports;